import { render, staticRenderFns } from "./recordNotesModal.vue?vue&type=template&id=480aa410&scoped=true&"
import script from "./recordNotesModal.vue?vue&type=script&lang=js&"
export * from "./recordNotesModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480aa410",
  null
  
)

export default component.exports