<template>
    <!--论文-->
    <div>
        <el-dialog :title="operation ? ('添加') :(optType?'编辑':'查看')" :visible.sync="SakaryVisible"  v-model="SakaryVisible" :close-on-click-modal="false">
            <el-form ref="SakaryForm"  :model="SakaryForm" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="论文名称:" prop="ThesisName" :rules="[ { required: true, message: '不能为空', trigger: 'blur' },  ]">
                            <el-input type="text" v-model="SakaryForm.ThesisName" :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="期刊名称:" prop="JournalName" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="text" v-model="SakaryForm.JournalName" :disabled ="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="期刊级别:" prop="JournalRank" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-input type="text" v-model="SakaryForm.JournalRank" :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发表年度:" prop="PublishYear" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-date-picker type="date"  v-model="SakaryForm.PublishYear" value-format="yyyy" placeholder="选择日期" :disabled ="!optType"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="作者单位:"  prop="AuthorUnit" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.AuthorUnit" :disabled ="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="SakaryForm.Remark" :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" v-if="optType">
                        <el-form-item label="发明人" label-width="120px">
                            <el-button @click="chooseUser()" >选择作者</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="SakaryForm.Persons"
                              style="width: 100%"
                              class="bg-color">
                        <el-table-column prop="UserName" label="姓名"></el-table-column>
                        <el-table-column label="操作" width="150" v-if="optType">
                            <template scope="scope">
                                <el-button size="small"
                                           v-on:click="PersonsDelete(scope.row)"
                                           type="text">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <upload-files :files="SakaryForm.Files" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles"
                              :IsDisabled="optType"  :IsDel="optType"></upload-files>
                <el-row v-if="optType">
                    <el-col :span="24" style="text-align: center">

                        <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>

                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog title="选择人员"
                   :visible.sync="addUserVisible"
                   v-model="addUserVisible"
                   width="75%"
                   center
                   append-to-body>
            <UserChoose :data="choosedusers"
                        :all="true"
                        :single="false"
                        @callback="usercallFunction"></UserChoose>
        </el-dialog>
    </div>
</template>

<script>
    import UserChoose from "../../components/UserChoose";
    import UploadFiles from "../../components/UploadFiles";
    import {
        ThesisAddInfo,
        ThesisUpdateInfo,
        ThesisGetInfoList,
    } from "../../api/hr";
    //import { getButtonList } from "../../promissionRouter";
    export default {
        components: { UserChoose, UploadFiles },
        data() {
            return {
                optType: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑
                loading: true,
                operation1: true,
                key: 0,
                SakaryVisible: false,
                choosedusers: [],
                
                addUserVisible: false,
                buttonList: [],
                SakaryForm: {
                    ThesisName: null,
                    JournalName: null,
                    JournalRank: null,
                    PublishYear: null,
                    AuthorUnit: null,
                    Remark: null,
                    Persons: [],
                    Files: [],
                },
                selectForm: {
                    thesisName: "",
                    journalName: "",
                    journalRank: "",
                    person: "",
                },
            };
        },
        methods: {
            onShowDlg(pid) { // 双击显示
                ThesisGetInfoList({ id: pid }).then((res) => {
                    this.SakaryForm = res.data.response;
                    this.key += 1;
                    this.SakaryVisible = true;
                });
                this.operation = false;
            },

            PersonsDelete(row) {
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.SakaryForm.Persons.splice(this.SakaryForm.Persons.indexOf(row), 1);
                });
            },
            usercallFunction(newdata) {
                for (var i = 0; i < newdata.length; i++) {
                    this.SakaryForm.Persons.push({
                        UserName: newdata[i].UserNameClaim,
                        UserId: newdata[i].Id,
                    });
                }

                //  this.SakaryForm.TalkPersons = userStr;
                this.addUserVisible = false;
            },
            chooseUser() {
                this.addUserVisible = true;
            },

            handleEdit() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                ThesisGetInfoList({ id: row.Id }).then((res) => {
                    console.log(res);
                    this.SakaryForm = res.data.response;
                    this.key += 1;
                    this.SakaryVisible = true;
                });

                this.operation = false;
            },
            
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.SakaryForm);
                            if (this.operation) {
                                //新增
                                ThesisAddInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.SakaryVisible = false;
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            } else {
                                //修改
                                ThesisUpdateInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "修改成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.SakaryVisible = false;
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            }
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },
            dealFiles(data) {
                console.log(data.backData);
                this.SakaryForm.Files = data.backData;
            },
        },
        mounted() {
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            let buttons = window.localStorage.buttList
                ? JSON.parse(window.localStorage.buttList)
                : [];
            //this.buttonList = getButtonList(this.$route.path, routers, buttons);
        },
    };
</script>

<style scoped>
</style>