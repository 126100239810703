<template>
    <!--试用-->
    <div>
        <el-dialog title="查看" :visible.sync="permissionShowVisible" v-model="permissionShowVisible" :close-on-click-modal="false">
            <el-form label-width="100px" ref="PermissionShowForm" :model="PermissionShowForm" class="demo-form-inline">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="岗位:" prop="Post">
                            <el-input type="text" :disabled="!optType" v-model="PermissionShowForm.Post">  </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="伙食日单价:" prop="EateDaySalary">
                            <el-select v-model="PermissionShowForm.EateDaySalary" :disabled="!optType" placeholder="伙食日单价" style="width: 100%">
                                <el-option v-for="item in EateSalaryLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="StartDate" label="开始日期:">
                            <el-date-picker type="date" v-model="PermissionShowForm.StartDate" value-format="yyyy-MM-dd" :disabled="!this.optType && !(this.type === 1)" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="试用期:" prop="ProbationExplain">
                            <el-select v-model="PermissionShowForm.ProbationExplain" placeholder="试用期" @change="ProbationExplainModel($event)" :disabled="!optType" style="width: 100%">
                                <el-option v-for="item in ProbationExplainLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否有年功:" prop="IsYear" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="PermissionShowForm.IsYear" placeholder="是否有年功" style="width: 100%" :disabled="!optType">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                                <el-option label="超龄年功" value="超龄年功"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="超龄年功工资:" prop="OverAgeUserAvailable">
                            <el-input type="text" v-model="PermissionShowForm.OverAgeUserAvailable" :disabled="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="日保健费:">
                            <el-select v-model="PermissionShowForm.CareDaySalary" placeholder="日保健费" style="width: 100%" :disabled="!optType">
                                <el-option v-for="item in CareDaySalaryLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="驻外补贴:">
                            <el-select v-model="PermissionShowForm.OutsideSalary" placeholder="驻外补贴" style="width: 100%" :disabled="!optType">
                                <el-option v-for="item in OutsideSalaryLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
                            <el-input type="text" v-model="PermissionShowForm.OndutyCareSalary" @blur="inputMoney('OndutyCareSalary')" :disabled="!optType">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工人系数:" prop="WorkerMod">
                            <el-input type="text" v-model="PermissionShowForm.WorkerMod" @blur="inputMoney('WorkerMod')" :disabled="!optType">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="租房补贴:" prop="HouseSalary">
                            <el-input type="text" v-model="PermissionShowForm.HouseSalary" @blur="inputMoney('HouseSalary')" :disabled="!optType">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="绩效工资:" prop="PerfSalary">
                            <el-input type="text" v-model="PermissionShowForm.PerfSalary" @blur="inputMoney('PerfSalary')" :disabled="!optType">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="特岗补贴:">
                            <el-input v-model="PermissionShowForm.SpecialSalary" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="转正待遇:">
                            <el-input v-model="PermissionShowForm.PositiveExplain" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="车辆补贴:">
                            <el-input v-model="PermissionShowForm.BusMoney" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="岗位工资:" prop="WorkSalary">
                            <el-input v-model="PermissionShowForm.WorkSalary" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="爱心基金:">
                            <el-input v-model="PermissionShowForm.LoveMoney" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="日工资:">
                            <el-input v-model="PermissionShowForm.DaySalary" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="银行:">
                            <el-input v-model="PermissionShowForm.BankCardName" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="银行卡号:">
                            <el-input v-model="PermissionShowForm.BankCardCD" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="结束日期:">
                            <el-date-picker type="date" :disabled="!this.optType && !(this.type === 1)" v-model="PermissionShowForm.EndDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="薪资类型:" prop="SalaryType">
                            <el-select v-model="PermissionShowForm.SalaryType" @change="SalaryTypeModel($event)" placeholder="薪资类型" style="width: 100%" :disabled="!optType">
                                <el-option v-for="item in SalaryTypeLists" :key="item.Id" :label="item.Type" :value="item.Type"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="职位待遇:" prop="PostSalaryName">
                            <el-select v-model="PermissionShowForm.PostSalaryName" @change="PositionModel($event)" placeholder="职位待遇" style="width: 100%" :disabled="!optType">
                                <el-option v-for="item in PositionLists" :key="item.Id" :label="item.LeaveName" :value="item.LeaveName"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注:" prop="Remark">
                            <el-input type="textarea" v-model="PermissionShowForm.Remark" :disabled="!optType">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="是否通过:" prop="State" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="PermissionShowForm.State" placeholder="请选择" style="width: 100%" :disabled="!optType">
                                <el-option label="试用通过" value="试用通过"></el-option>
                                <el-option label="试用未通过" value="试用未通过"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <upload-files :files="PermissionShowForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" v-bind="upLoadPost"></upload-files>
                <el-row v-if="optType">
                    <el-col v-if="type == 0" :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="PermissionSubmit('PermissionShowForm')">提交</el-button>
                    </el-col>
                    <el-col v-else-if="type == 1" :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="DateSubmit('PermissionShowForm')">提交日期修改</el-button>
                    </el-col>
                    <el-col v-else :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="PermissionSubmit('PermissionShowForm')">提交</el-button>
                    </el-col>
                </el-row>
                <el-row v-else>
                    <el-col v-if="type == 1" :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="DateSubmit('PermissionShowForm')">提交日期修改</el-button>
                    </el-col>
                    <el-col v-else :span="24" style="text-align: center">
                        
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getPositiveDetail,
        ProbationUpdateInfo,
        ProbationUpdateDateInfo,
    } from "../../api/hr";
    import UploadFiles from "../../components/UploadFiles";

    export default {
        components: { UploadFiles },
        data() {
            return {
                type:0,
                optType: false,      // 编辑/查看
                PermissionShowForm: [],
                permissionShowVisible: false,

                SalaryTypeLists: [], // 薪资类型
                PositionLists: [], // 职位待遇列表
                key: 0,

                upLoadPost: { // upload-files 传值
                    IsDisabled: false,
                    IsDel: false
                },
                EateSalaryLists: [
                    {
                        value: "0",
                        label: "0",
                    },
                    {
                        value: "5",
                        label: "5",
                    },
                    {
                        value: "10",
                        label: "10",
                    },
                    {
                        value: "25",
                        label: "25",
                    },
                    {
                        value: "30",
                        label: "30",
                    },
                ],
                CareDaySalaryLists: [
                    {
                        value: "5",
                        label: "5",
                    },
                    {
                        value: "10",
                        label: "10",
                    },
                ],
                OutsideSalaryLists: [
                    {
                        value: "300",
                        label: "300",
                    },
                    {
                        value: "500",
                        label: "500",
                    },
                ],
                ProbationExplainLists: [
                    {
                        value: "0",
                        label: "无",
                    },
                    {
                        value: "7",
                        label: "7天",
                    },
                    {
                        value: "1",
                        label: "1个月",
                    },
                    {
                        value: "2",
                        label: "2个月",
                    },
                    {
                        value: "3",
                        label: "3个月",
                    },
                    {
                        value: "5",
                        label: "5个月",
                    },
                ],
            }
        },
        methods: {
            // 获取数据显示
            onShowDlg(pid) {
                console.log("!optType || type===1:", !this.optType && !(this.type === 1))
                console.log(pid);
                getPositiveDetail({ id: pid }).then((res) => {
                    console.log(res);
                    this.PermissionShowForm = res.data.response;
                    this.key += 1;
                    this.permissionShowVisible = true;
                });
            },
            onShowEditDateDlg(pid) {
                this.type = 1; // 修改日期

                console.log("!optType || type===1:", !this.optType && !(this.type === 1))
                
                console.log(pid);
                getPositiveDetail({ id: pid }).then((res) => {
                    console.log(res);
                    this.PermissionShowForm = res.data.response;
                    this.key += 1;
                    this.permissionShowVisible = true;
                });
            },
            DateSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    let para = Object.assign({}, this.PermissionShowForm);
                    ProbationUpdateDateInfo(para).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: "修改成功",
                                type: "success",
                                duration: 5000,
                            });
                            this.permissionShowVisible = false;
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                    this.getProbation();
                });
            },
            PermissionSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.PermissionShowForm);
                            ProbationUpdateInfo(para).then((res) => {
                                if (res.data.success) {
                                    this.$message({
                                        message: "修改成功",
                                        type: "success",
                                        duration: 5000,
                                    });
                                    this.permissionShowVisible = false;
                                } else {
                                    this.$message({
                                        message: res.data.msg,
                                        type: "error",
                                        duration: 5000,
                                    });
                                }
                            });
                            this.getProbation();
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                });
            },
            dealFiles(data) {
                console.log(data.backData);
                this.PermissionShowForm.FileArry = data.backData;
                // this.fileList = data.backData;
                // console.log('dealFiles-list:' + this.fileList);
            },
        }
    }
</script>

<style scoped>
</style>