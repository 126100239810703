<template>
    <section class="new_css">
        <el-row class="new_header">
            <!-- 查询项 -->
            
            <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
                <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
                    <el-form-item label="姓名:">
                        <el-input v-model="selectForm.userName" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位:">
                        <el-input v-model="selectForm.post" placeholder="岗位"></el-input>
                    </el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <!--列表-->
            <el-table :data="users" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleEdit" class="new_table">
                <el-table-column label="姓名" prop="UserName" width="100"></el-table-column>
                <el-table-column label="职位" prop="PostName"></el-table-column>
                <el-table-column label="政治面貌" prop="PoliticsStatus" width="100"></el-table-column>
                <el-table-column label="人员状态" width="80" prop="State"></el-table-column>
                <el-table-column label="入职时间" prop="workDate" :formatter="formatStartTime"></el-table-column>
                <el-table-column label="薪资信息" width="100" prop="workSalary"></el-table-column>
                <!-- <el-table-column label="职级信息" prop="Professional"></el-table-column> -->
                <el-table-column label="职称信息" prop="Professional"></el-table-column>
                <el-table-column label="竞聘调岗信息" prop="OutPostName"></el-table-column>
                <el-table-column label="专利信息" prop="patentCount"></el-table-column>
                <el-table-column label="论文信息" prop="thesisCount"></el-table-column>
                <el-table-column label="持证信息" prop="CerName"></el-table-column>
                <el-table-column label="培训经历" prop="TrainCount"></el-table-column>
                <el-table-column label="奖励信息" prop="RewardCount"></el-table-column>
                <el-table-column label="惩处信息" prop="PunishCount"></el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata">
        </el-pagination>

        <!-- 员工详情开始 -->
        <el-dialog title="员工详情" :visible.sync="SakaryVisible" v-model="SakaryVisible" :close-on-click-modal="false" width="100%">
            <div class="new_content" style="width: 100%; height: 80%; margin-top: 20px; background: #ffffff">
                <el-tabs v-model="activeName" style="margin-left: 10px" @tab-click="handleClick">
                    <el-tab-pane label="基本信息" name="基本信息">
                        <el-row :gutter="20" style="margin-top:10px;white-space:nowrap;over">
                            <el-col :span="5">
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div class="name-role" v-if="operation">
                                            <el-upload class="avatar-uploader" :headers="token" action="https://api.gradgroup.cn/ftp/ftp/upload" :show-file-list="false" :on-success="OSOnSuccess">
                                                <img v-if="userForm.ImageURL" v-lazy="userForm.ImageURL" class="avatar" style="width: 150px;" />
                                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                            </el-upload>
                                        </div>
                                        <div class="name-role" v-else>
                                            <img v-lazy="userForm.ImageURL" style="width: 150px;" />
                                        </div>
                                        <el-divider></el-divider>
                                        <div class="personal-relation">
                                            <div class="relation-item">姓名:
                                                <div style="float: right; padding-right: 20px">
                                                    {{ userForm.UserName }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="personal-relation">
                                            <div class="relation-item">
                                                登录账号:
                                                <div style="float: right; padding-right: 20px">
                                                    {{ userForm.UserCode }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="personal-relation">
                                            <div class="relation-item">
                                                部门:
                                                <div style="float: right; padding-right: 20px">
                                                    {{ userForm.Dpt }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="personal-relation">
                                            <div class="relation-item">
                                                岗位:
                                                <div style="float: right; padding-right: 20px">
                                                    {{ userForm.Post }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="personal-relation">
                                            <div class="relation-item">
                                                入职日期:
                                                <div style="float: right; padding-right: 20px">
                                                    {{ userForm.Hiredate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="personal-relation">
                                            <div class="relation-item">
                                                手机号:
                                                <div style="float: right; padding-right: 20px">
                                                    {{ userForm.MobilePhone }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="personal-relation">
                                            <div class="relation-item">
                                                状态:
                                                <div style="float: right">
                                                    <el-select v-model="userForm.IsAuthorizedStrengthName" placeholder="请选择" style="width: 50%; float: right" size="mini" @change="IsAuthorizedStrengthChange($event)">
                                                        <el-option label="在编" value="在编"></el-option>
                                                        <el-option label="不在编" value="不在编"></el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>
                            <el-col :span="19">
                                <el-form ref="dataForm" label-width="100px" :model="dataForm" size="mini">
                                    <div class="grid-content bg-purple">
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>基本信息</span>
                                            </div>
                                            <el-row :gutter="20">
                                                <el-col :span="8">
                                                    <el-form-item label="填单日期:" prop="ApplyDate">
                                                        {{ dataForm.ApplyDate }}
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="姓名:" prop="Name">
                                                        <el-input v-model="dataForm.Name" :disabled="true"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="身份证号:" prop="IDCard">
                                                        <div>
                                                            {{ dataForm.IDCard }}
                                                        </div>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="出生年月:" prop="Birthday">
                                                        <el-date-picker type="date" v-model="dataForm.Birthday" value-format="yyyy-MM-dd" placeholder="选择出生年月"></el-date-picker>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="性别:">
                                                        <el-radio-group v-model="dataForm.Sex">
                                                            <el-radio label="男"></el-radio>
                                                            <el-radio label="女"></el-radio>
                                                        </el-radio-group>
                                                    </el-form-item>
                                                </el-col>

                                                <el-col :span="8">
                                                    <el-form-item label="民族:" prop="Nationality">
                                                        <el-input v-model="dataForm.Nationality"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="政治面貌:" prop="PoliticsStatus">
                                                        <el-select v-model="dataForm.PoliticsStatus" placeholder="选择政治面貌">
                                                            <el-option v-for="item in poTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="婚姻状况:" prop="MarriedStatus">
                                                        <el-select v-model="dataForm.MarriedStatus" placeholder="选择婚姻状况">
                                                            <el-option v-for="item in qyTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="职称证书:" prop="Position">
                                                        <el-select v-model="dataForm.Position" placeholder="选择职称证书">
                                                            <el-option v-for="item in tiTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="技能证书:" prop="skillCert">
                                                        <el-select v-model="dataForm.skillCert" placeholder="选择技能证书">
                                                            <el-option v-for="item in jnTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                        <!--<el-input v-model="dataForm.skillCert"></el-input>-->
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="全日制学历 :" prop="FirstDiploma">
                                                        <el-select v-model="dataForm.FirstDiploma" placeholder="选择第一学历">
                                                            <el-option v-for="item in edTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="非全日制学历:" prop="TopDiploma">
                                                        <el-select v-model="dataForm.TopDiploma" placeholder="选择非全日制学历">
                                                            <el-option v-for="item in edTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="外语语种及级别:">
                                                        <el-input v-model="dataForm.LanguageLevel"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="籍贯:" prop="NativePlace">
                                                        <el-input v-model="dataForm.NativePlace"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="户籍地址:" prop="IDAddress">
                                                        <el-input v-model="dataForm.IDAddress" placeholder="请输入户籍地址"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="户口性质:" prop="NativeStatus">
                                                        <el-select v-model="dataForm.NativeStatus" placeholder="选择户口性质">
                                                            <el-option v-for="item in adTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="通讯地址:" prop="Address">
                                                        <el-input v-model="dataForm.Address" placeholder="请输入通讯地址"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="手机号码:" prop="MobilePhone">
                                                        <el-input v-model="dataForm.MobilePhone" maxlength="11" show-word-limit placeholder="请输入有效手机号" @blur="phonecheck"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="电子邮箱:">
                                                        <el-input v-model="dataForm.Email" placeholder="请输入电子邮箱"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="招聘来源:">
                                                        <el-select v-model="dataForm.ApplySource" placeholder="选择招聘来源">
                                                            <el-option v-for="item in applyTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="应聘岗位:">
                                                        <el-input v-model="dataForm.ApplyJob" placeholder="请输入应聘岗位"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="介绍人:">
                                                        <el-input v-model="dataForm.IntroducerName" placeholder="介绍人"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="紧急联系人/姓名:" label-width="140px">
                                                        <el-input v-model="dataForm.Emergency" placeholder="请输入紧急联系人/姓名"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="紧急联系人/电话:" label-width="140px">
                                                        <el-input v-model="dataForm.EmergencyPhone" placeholder="请输入紧急联系人/电话"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="可开始工作时间:" label-width="140px">
                                                        <el-date-picker type="date" v-model="dataForm.ApplyStartDate" value-format="yyyy-MM-dd" placeholder="选择可开始工作时间"></el-date-picker>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <el-form-item label="特殊人员:">
                                                        <el-select v-model="dataForm.SpecialPersonnel" multiple placeholder="可多选" style="width: 100%">
                                                            <el-option label="退伍军人" value="退伍军人"></el-option>
                                                            <el-option label="失业人员" value="失业人员"></el-option>
                                                            <el-option label="残疾证" value="残疾证"></el-option>
                                                            <el-option label="无" value="无"></el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-card>
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>健康调查</span>
                                            </div>
                                            <el-row>
                                                <el-table :data="dataForm.Diseases" style="width: 100%" class="bg-color">
                                                    <el-table-column prop="DiseaseType" label="病史类型"></el-table-column>
                                                    <el-table-column prop="IsYes" label="状态"></el-table-column>
                                                    <el-table-column prop="Explain" label="说明"></el-table-column>
                                                </el-table>
                                            </el-row>
                                        </el-card>
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>学习经历(高中,大学，在职进修)</span>
                                            </div>
                                            <el-row>
                                                <el-form :inline="true">
                                                    <el-form-item>
                                                        <el-button class="add-btn" type="primary" v-on:click="customerhandleAdd">新增</el-button>
                                                    </el-form-item>
                                                </el-form>
                                                <el-table :data="dataForm.Educateds" style="width: 100%">
                                                    <el-table-column prop="EnterTime" label="开始时间" :formatter="formatStartTime"></el-table-column>
                                                    <el-table-column prop="GraduateTime" label="结束时间" :formatter="formatStartTime"></el-table-column>
                                                    <el-table-column prop="GradauteSchool" label="学校名称"></el-table-column>
                                                    <el-table-column prop="Speciality" label="专业"></el-table-column>
                                                    <el-table-column prop="Certificate" label="学历"></el-table-column>
                                                    <el-table-column prop="Voucher" label="证明人及电话"></el-table-column>
                                                    <el-table-column label="操作" width="150">
                                                        <template scope="scope">
                                                            <el-button @click="CustomerhandleDetail(scope.row, scope.$index)" type="text" size="small">修改</el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-row>
                                        </el-card>
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>培训经历</span>
                                            </div>
                                            <el-row>
                                                <el-form :inline="true">
                                                    <el-form-item>
                                                        <el-button class="add-btn" type="primary" v-on:click="trainAdd">新增</el-button>
                                                    </el-form-item>
                                                </el-form>
                                                <el-table :data="dataForm.Traineds" style="width: 100%">
                                                    <el-table-column prop="StartTime" label="开始时间" :formatter="formatStartTime"></el-table-column>
                                                    <el-table-column prop="EndTime" label="结束时间" :formatter="formatStartTime"></el-table-column>
                                                    <el-table-column prop="Institutions" label="培训机构"></el-table-column>
                                                    <el-table-column prop="Details" label="培训内容"></el-table-column>
                                                    <el-table-column prop="InCertificate" label="证书名称"></el-table-column>
                                                    <el-table-column label="操作" width="150">
                                                        <template scope="scope">
                                                            <el-button @click="TrainingExperienceDetail(scope.row,scope.$index)" type="text" size="small">修改</el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-row>
                                        </el-card>
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>工作经历</span>
                                            </div>
                                            <el-row>
                                                <el-form :inline="true">
                                                    <el-form-item>
                                                        <el-button class="add-btn" type="primary" v-on:click="workAdd">新增</el-button>
                                                    </el-form-item>
                                                </el-form>
                                                <el-table :data="dataForm.Workeds" style="width: 100%">
                                                    <el-table-column prop="StartTime" label="开始时间" :formatter="formatStartTime"></el-table-column>
                                                    <el-table-column prop="EndTime" label="结束时间" :formatter="formatStartTime"></el-table-column>
                                                    <el-table-column prop="WorkAddress" label="工作单位"></el-table-column>
                                                    <el-table-column prop="LeavingReason" label="职位/岗位"></el-table-column>
                                                    <el-table-column prop="BecaseWork" label="离职原因"></el-table-column>
                                                    <el-table-column prop="WorkVoucher" label="证明人及电话"></el-table-column>
                                                    <el-table-column label="操作" width="150">
                                                        <template scope="scope">
                                                            <el-button @click="WorkExperienceDetail(scope.row, scope.$index)" type="text" size="small">修改</el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-row>
                                        </el-card>
                                        <el-card class="box-card">
                                            <div slot="header" class="clearfix">
                                                <span>家庭成员</span>
                                            </div>
                                            <el-row>
                                                <el-form :inline="true">
                                                    <el-form-item>
                                                        <el-button class="add-btn" type="primary" v-on:click="familyAdd">新增</el-button>
                                                    </el-form-item>
                                                </el-form>
                                                <el-table :data="dataForm.Familys" style="width: 100%">
                                                    <el-table-column prop="FaName" label="姓名"></el-table-column>
                                                    <el-table-column prop="Relationship" label="与本人关系"></el-table-column>
                                                    <el-table-column prop="WorkDpt" label="职位/岗位"></el-table-column>
                                                    <el-table-column prop="WorkUnits" label="工作单位"></el-table-column>
                                                    <el-table-column prop="PhoneNo" label="联系电话"></el-table-column>
                                                    <el-table-column label="操作" width="150">
                                                        <template scope="scope">
                                                            <el-button @click="FamilyDetail(scope.row, scope.$index)" type="text" size="small">修改</el-button>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <span>备注:</span><el-input type="textarea" v-model="dataForm.Remark"></el-input>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="12" class="list-title">
                                                    <div class="intro">
                                                        <p>知识产权情况调查<span style="color: red">*</span></p>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="6">
                                                    <span>在原单位工作的岗位是否涉及知识产权:</span>
                                                </el-col>
                                                <el-col :span="18">
                                                    <el-form-item label="">
                                                        <el-switch v-model="dataForm.IsIPR" active-text="是" inactive-text="否">
                                                        </el-switch>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <span>请具体列举收及的知识产权内容:</span>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <!--<span>请具体列举收及的知识产权内容:</span>-->
                                                    <el-input type="textarea" v-model="dataForm.IPRContent"></el-input>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="6">
                                                    <span>是否与原单位签订过竞业限制条款:</span>
                                                </el-col>
                                                <el-col :span="18">
                                                    <el-form-item label="">
                                                        <el-switch v-model="dataForm.IsAgreement" active-text="是" inactive-text="否">
                                                        </el-switch>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <span>请写明竞业限制条款的生效时间和年限:</span>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="24">
                                                    <!--<span>请写明竞业限制条款的生效时间和年限:</span>-->
                                                    <el-input type="textarea" v-model="dataForm.AgreementContent"></el-input>
                                                </el-col>
                                            </el-row>
                                            <el-row v-if="operation">
                                                <el-col :span="24" style="text-align: center">
                                                    <el-form-item>
                                                        <el-button type="primary" @click="submitForm('dataForm')">确认修改</el-button>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-card>
                                    </div>
                                </el-form>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="档案资料" name="档案资料">
                        <el-form ref="SalaryBanksForm" :model="UploadForm" class="demo-form-inline" label-width="120px">
                            <el-row>
                                <upload-files :files="UploadForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :IsDisabled="operation" :IsDel="operation" :limit="50" @fun="dealFiles"></upload-files>
                            </el-row>
                            <el-row v-if="operation">
                                <el-col :span="24" style="text-align: center">
                                    <el-button type="primary" v-on:click="UploadFormSubmit('UploadForm')">提交</el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="档案信息" name="档案信息">档案信息</el-tab-pane> -->
                    <el-tab-pane label="合同信息" name="合同信息">
                        <el-table :data="Contract" highlight-current-row>
                            <el-table-column prop="UserName" label="姓名"> </el-table-column>
                            <el-table-column label="合同类型" prop="ConTypeName"></el-table-column>
                            <el-table-column label="合同开始时间" prop="StartDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="合同结束时间" prop="EndDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="合同期限" prop="ConDate"></el-table-column>
                            <el-table-column label="类型" prop="Type"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    
                    <el-tab-pane label="薪资信息" name="薪资调整记录">
                        <el-button @click="resetDateFilter" type="primary">原系统记录</el-button>
                        <el-table :data="SalaryBanks" highlight-current-row :row-class-name="tableRowClassName">
                            <el-table-column label="确认时间" prop="VerifyDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="岗位工资" prop="WorkSalary"></el-table-column>
                            <el-table-column label="职务工资" prop="PostSalary"></el-table-column>
                            <el-table-column label="绩效工资" prop="PerfSalary"></el-table-column>
                            <el-table-column label="工资类型" prop="SalaryType"></el-table-column>
                            <el-table-column label="是否有年功" prop="IsYear"></el-table-column>
                            <el-table-column label="备注" prop="Remark"></el-table-column>
                            <el-table-column label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button @click="SalaryBanksDetail(scope.row)" type="text" size="small">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="试用信息" name="试用信息">
                        <el-table :data="Probation" @row-dblclick="ondblclick" highlight-current-row>
                            <el-table-column prop="Factory" label="公司名称"> </el-table-column>
                            <el-table-column prop="Dpt" label="部门名称"> </el-table-column>
                            <el-table-column label="试用岗位" prop="Post"></el-table-column>
                            <el-table-column label="开始日期" prop="StartDate"> </el-table-column>
                            <el-table-column label="试用期" prop="ProbationExplain"></el-table-column>
                            <el-table-column label="结束日期" prop="EndDate"></el-table-column>
                            <el-table-column label="岗位工资" prop="WorkSalary"></el-table-column>
                            <el-table-column label="日工资" prop="DaySalary"></el-table-column>
                            <el-table-column label="转正待遇" prop="PositiveExplain"></el-table-column>
                            <el-table-column label="备注" prop="Remark"></el-table-column>
                        </el-table>
                    </el-tab-pane>

                    <el-tab-pane label="转正信息" name="转正信息">
                        <el-table :data="Positive" highlight-current-row>
                            <el-table-column prop="UserInfoUserName" label="姓名"></el-table-column>
                            <el-table-column prop="Factory" label="公司名称"> </el-table-column>
                            <el-table-column prop="Dpt" label="部门名称"> </el-table-column>
                            <el-table-column prop="Post" label="岗位名称"> </el-table-column>
                            <el-table-column label="转正日期" prop="StartDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="薪资类型" prop="DicSalaryTypeType"></el-table-column>
                            <el-table-column label="职务待遇" prop="DicPositionLeaveName"></el-table-column>
                            <el-table-column label="调动时间" prop="TransferDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button @click="PositiveDetail(scope.row)" type="text" size="small">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="调动信息" name="调动信息">
                        <el-table :data="Transfer" highlight-current-row>
                            <el-table-column prop="UserName" label="名称"> </el-table-column>
                            <el-table-column label="调入公司" prop="IntoFactory"></el-table-column>
                            <el-table-column label="调入部门" prop="IntoDpt"></el-table-column>
                            <el-table-column label="调入岗位" prop="IntoPost"></el-table-column>
                            <el-table-column label="调出公司" prop="OutFactory"></el-table-column>
                            <el-table-column label="调出部门" prop="OutDpt"></el-table-column>
                            <el-table-column label="调出岗位" prop="OutPost"></el-table-column>
                            <el-table-column label="用工性质" prop="ContractNature"></el-table-column>
                            <el-table-column label="调动时间" prop="TransferDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button @click="TransferDetail(scope.row)" type="text" size="small">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="奖惩信息" name="奖惩信息">
                        <el-table :data="Rewards" highlight-current-row>
                            <el-table-column prop="UserName" label="姓名"> </el-table-column>
                            <el-table-column label="部门" prop="Dpt"></el-table-column>
                            <el-table-column label="岗位" prop="Post"></el-table-column>
                            <el-table-column label="发布部门" prop="IssuedDepartment"></el-table-column>
                            <el-table-column label="执行日期" prop="ExecuteDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="执行方式" prop="ExecuteType"></el-table-column>
                            <el-table-column label="类型" prop="Type"></el-table-column>
                            <el-table-column label="金额" prop="Salary"></el-table-column>
                            <el-table-column label="具体描述" show-overflow-tooltip prop="Explain"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="集团及外部荣誉" name="荣誉信息">
                        <el-table :data="Honor" highlight-current-row>
                            <el-table-column prop="UserName" label="姓名"> </el-table-column>
                            <el-table-column label="公司" prop="Factory"></el-table-column>
                            <el-table-column label="部门" prop="Dpt"></el-table-column>
                            <el-table-column label="岗位" prop="Post"></el-table-column>
                            <el-table-column label="分类" prop="Type"></el-table-column>
                            <el-table-column label="是否在公司" prop="IsFactory">
                                <template slot-scope="scope">
                                    <el-tag :type="scope.row.IsFactory ? 'success' : 'danger'" disable-transitions>
                                        {{ scope.row.IsFactory ? "是" : "否" }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="荣誉级别" prop="Level"></el-table-column>
                            <el-table-column label="获取时间" prop="GetDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="审核状态" prop="State">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.State == '已确认'" type="success" disable-transitions>
                                        {{ scope.row.State }}
                                    </el-tag>
                                    <el-tag v-else-if="scope.row.State == '已退回'" type="danger" disable-transitions>
                                        {{ scope.row.State }}
                                    </el-tag>
                                    <el-tag v-else-if="scope.row.State == '未确认'" type="primary" disable-transitions>
                                        {{ scope.row.State }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button @click="HonorDetail(scope.row)" type="text" size="small">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>

                        <!-- 分页器 -->
                        <el-pagination align="center" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-size="pageSize1" layout="prev, pager, next, jumper,total" :page-count="total1" :total="totaldata1">
                        </el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="证书信息" name="证书信息">
                        <el-table :data="Certificate" highlight-current-row>
                            <el-table-column prop="UserName" label="姓名"> </el-table-column>
                            <el-table-column prop="CertType" label="证书系列">
                            </el-table-column>
                            <el-table-column label="证书名称" prop="CertName"></el-table-column>
                            <el-table-column label="证书类别" prop="CertCategory"></el-table-column>
                            <el-table-column label="专业" prop="CertProfession"></el-table-column>
                            <el-table-column label="证书状态" prop="CertState"></el-table-column>
                            <el-table-column label="补贴金" prop="SubsidyMoney"></el-table-column>
                            <el-table-column label="操作" width="100">
                                <template slot-scope="scope">
                                    <el-button @click="CertificateDetail(scope.row)" type="text" size="small">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="专利信息" name="专利信息">
                        <el-table :data="PatentList" @row-dblclick="ondblclick" highlight-current-row>
                            <el-table-column label="序号" prop="RowIndex"></el-table-column>
                            <el-table-column label="专利类型" prop="Type"></el-table-column>
                            <el-table-column label="专利名称" prop="PatentName"> </el-table-column>
                            <el-table-column label="专利号" prop="PatentNumber"></el-table-column>
                            <el-table-column label="申请人" prop="Applicant"></el-table-column>
                            <el-table-column label="专利法律状态" prop="State"></el-table-column>
                            <el-table-column label="授权公众号" prop="AnnouncementNumber"></el-table-column>
                            <el-table-column label="证书编号" prop="CertificateNumber"></el-table-column>
                            <el-table-column label="申请日期" prop="ApplicationDate"></el-table-column>
                            <el-table-column label="授权日期" prop="AuthorizationDate"></el-table-column>
                            <el-table-column label="有效日期" prop="EffectiveDate"></el-table-column>
                            <el-table-column label="获取方式" prop="AcquireWay"></el-table-column>
                            <el-table-column label="申请年度" prop="ApplicationYear"></el-table-column>
                            <el-table-column label="授权年度" prop="AnnouncementYear"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="论文信息" name="论文信息">
                        <el-table :data="ThesisList" @row-dblclick="ondblclick" highlight-current-row>
                            <el-table-column label="序号" prop="RowIndex"></el-table-column>
                            <el-table-column label="论文名称" prop="ThesisName"></el-table-column>
                            <el-table-column label="期刊名称" prop="JournalName"> </el-table-column>
                            <el-table-column label="期刊级别" prop="JournalRank"></el-table-column>
                            <el-table-column label="发表年度" prop="PublishYear"></el-table-column>
                            <el-table-column label="作者单位" prop="AuthorUnit"></el-table-column>
                            <el-table-column label="备注" prop="Remake"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="内部职称" name="职称信息">
                        <el-table :data="Professions" highlight-current-row>
                            <el-table-column prop="UserName" label="姓名"> </el-table-column>
                            <el-table-column label="公司" prop="Factory"></el-table-column>
                            <el-table-column label="部门" prop="Dpt"></el-table-column>
                            <el-table-column label="岗位" prop="Post"></el-table-column>
                            <el-table-column label="职称类型" prop="Type"></el-table-column>
                            <el-table-column label="评聘级别" prop="Rank"></el-table-column>
                            <el-table-column label="评聘档级" prop="Level"></el-table-column>
                            <el-table-column label="职称工资" prop="Salary"></el-table-column>
                            <el-table-column label="评聘日期" prop="JudgedDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="发放日期" prop="ExecuteDate" :formatter="formatStartTime"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="首席工程师" name="首席工程师">
                        <el-table :data="ProfessionTop" highlight-current-row>
                            <el-table-column prop="UserName" label="姓名"> </el-table-column>
                            <el-table-column label="公司" prop="Factory"></el-table-column>
                            <el-table-column label="部门" prop="Dpt"></el-table-column>
                            <el-table-column label="岗位" prop="Post"></el-table-column>
                            <el-table-column label="首席工程师工资" prop="Salary"></el-table-column>
                            <el-table-column label="评聘日期" prop="JudgedDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="发放日期" prop="ExecuteDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="方向" prop="Profession"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="离职记录" name="离职记录">
                        <el-table :data="Dimission" highlight-current-row>
                            <el-table-column prop="UserName" label="姓名"> </el-table-column>
                            <el-table-column label="公司" prop="Factory"></el-table-column>
                            <el-table-column label="部门" prop="Dpt"></el-table-column>
                            <el-table-column label="岗位" prop="Post"></el-table-column>
                            <el-table-column label="离职时间" prop="DimissionDate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="聘用时间" prop="Hiredate" :formatter="formatStartTime"></el-table-column>
                            <el-table-column label="离职类型" prop="Type"></el-table-column>
                            <el-table-column label="员工性质" prop="ContractNature"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="培训信息" name="培训信息">
                        <el-table :data="TrainList" highlight-current-row>
                            <el-table-column prop="Apply_QRCode" label="培训编号"> </el-table-column>
                            <el-table-column prop="ProjectType" label="项目类型"> </el-table-column>
                            <el-table-column prop="Apply_ProjectName" label="培训名称"> </el-table-column>
                            <el-table-column prop="TrainWay" label="培训方式"> </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-dialog>
        <el-dialog title="薪资调整记录详情" :visible.sync="SalaryBanksVisible" v-model="SalaryBanksVisible" :close-on-click-modal="false">
            <el-form ref="SalaryBanksForm" :model="SalaryBanksForm" class="demo-form-inline" label-width="120px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="UserName">
                            <el-input type="text" v-model="SalaryBanksForm.UserName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="确认时间:" prop="VerifyDate">
                            <el-input type="text" v-model="SalaryBanksForm.VerifyDate" :formatter="formatStartTime" :disabled="true">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="银行名称:" prop="BankCardName">
                            <el-input type="text" v-model="SalaryBanksForm.BankCardName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="银行卡号:" prop="BankCardCD">
                            <el-input type="text" v-model="SalaryBanksForm.BankCardCD">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否有年功:" prop="IsYear">
                            <el-select v-model="SalaryBanksForm.IsYear" placeholder="是否有年功" style="width: 100%">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                                <el-option label="超龄年功" value="超龄年功"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="超龄年功工资:" prop="OverAgeUserAvailable">
                            <el-input type="text" v-model="SalaryBanksForm.OverAgeUserAvailable">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="岗位工资:" prop="WorkSalary">
                            <el-input type="text" v-model="SalaryBanksForm.WorkSalary">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职务工资:" prop="PostSalary">
                            <el-select v-model="SalaryBanksForm.PostSalary" placeholder="职务工资" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工资类型:" prop="SalaryType">
                            <el-select v-model="SalaryBanksForm.SalaryType" placeholder="工资类型" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="日保健费:" prop="CareDaySalary">
                            <el-select v-model="SalaryBanksForm.CareDaySalary" placeholder="日保健费" style="width: 100%">
                                <el-option label="0" value="0"></el-option>
                                <el-option label="5" value="5"></el-option>
                                <el-option label="10" value="10"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="日工资单价:" prop="DaySalary">
                            <el-input type="text" v-model="SalaryBanksForm.DaySalary">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="特岗补贴:" prop="SpecialSalary">
                            <el-input type="text" v-model="SalaryBanksForm.SpecialSalary">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="伙食单价:" prop="EateDaySalary">
                            <el-input type="text" v-model="SakaryForm.EateDaySalary">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="驻外补贴:" prop="OutsideSalary">
                            <el-input type="text" v-model="SalaryBanksForm.OutsideSalary">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="车辆补贴:" prop="BusMoney">
                            <el-input type="text" v-model="SalaryBanksForm.BusMoney">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
                            <el-input type="text" v-model="SalaryBanksForm.OndutyCareSalary">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="爱心基金:" prop="LoveMoney">
                            <el-input type="text" v-model="SalaryBanksForm.LoveMoney">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工人系数:" prop="WorkerMod">
                            <el-input type="text" v-model="SalaryBanksForm.WorkerMod">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="租房补贴:" prop="HouseSalary">
                            <el-input type="text" v-model="SalaryBanksForm.HouseSalary">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="绩效工资:" prop="PerfSalary">
                            <el-input type="text" v-model="SalaryBanksForm.PerfSalary">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注:" prop="Remark">
                            <el-input type="textarea" v-model="SalaryBanksForm.Remark">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog title="转正详情" :visible.sync="permissionShowVisible" v-model="permissionShowVisible" :close-on-click-modal="false">
            <el-form label-width="120px" ref="PermissionForm" :model="PermissionForm">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="岗位:" prop="Post">
                            <el-input type="text" :disabled="true" v-model="PermissionForm.Post">
                                <!-- <el-button slot="append" icon="el-icon-search" @click="chooseUser"></el-button> -->
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="伙食日单价:" prop="EateSalary">
                            <el-select v-model="PermissionForm.EateSalary" placeholder="伙食日单价" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="StartDate" label="转正日期:">
                            <el-date-picker type="date" v-model="PermissionForm.StartDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="转正工资执行时间:" prop="SalaryDate">
                            <el-date-picker type="month" v-model="PermissionForm.SalaryDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="日保健费:">
                            <el-select v-model="PermissionForm.CareDaySalary" placeholder="日保健费" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="驻外补贴:">
                            <el-select v-model="PermissionForm.OutsideSalary" placeholder="驻外补贴" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="特岗补贴:">
                            <el-input v-model="PermissionForm.SpecialSalary"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="车辆补贴:">
                            <el-input v-model="PermissionForm.BusSalary"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="岗位工资:">
                            <el-input v-model="PermissionForm.WorkSalary"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="日工资:">
                            <el-input v-model="PermissionForm.DaySalary"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="爱心基金:">
                            <el-input v-model="PermissionForm.FundsDeduct"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="银行:">
                            <el-input v-model="PermissionForm.BankCardName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开户行:">
                            <el-input v-model="PermissionForm.BankAddress"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="银行卡号:">
                            <el-input v-model="PermissionForm.BankCardNo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="薪资类型:" prop="DicSalaryTypeType">
                            <el-select v-model="PermissionForm.DicSalaryTypeType" @change="SalaryTypeModel($event)" placeholder="薪资类型" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="职位待遇:" prop="DicPositionLeaveName">
                            <el-select v-model="PermissionForm.DicPositionLeaveName" @change="PositionModel($event)" placeholder="职位待遇" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <upload-files :files="PermissionForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" :IsDisabled="false" :IsDel="false"></upload-files>
            </el-form>
        </el-dialog>
        <el-dialog title="荣誉详情" :visible.sync="HonorVisible" v-model="HonorVisible" :close-on-click-modal="false">
            <el-form ref="HonorForm" :model="HonorForm" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="UserName">
                            <el-input type="text" v-model="HonorForm.UserName"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="公司:" prop="Factory">
                            <el-input type="text" v-model="HonorForm.Factory"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="部门:" prop="Dpt">
                            <el-input type="text" v-model="HonorForm.Dpt"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="岗位:" prop="Post">
                            <el-input type="text" v-model="HonorForm.Post"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="分类:" prop="Type">
                            <el-select v-model="HonorForm.Type" placeholder="请选择" style="width: 100%">
                                <el-option label="荣誉称号" value="荣誉称号"></el-option>
                                <el-option label="荣誉奖励" value="荣誉奖励"></el-option>
                                <el-option label="专利" value="专利"></el-option>
                                <el-option label="论文" value="论文"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="荣誉级别:" prop="Level">
                            <el-select v-model="HonorForm.Level" placeholder="请选择" style="width: 100%">
                                <el-option label="国家级" value="国家级"></el-option>
                                <el-option label="省级" value="省级"></el-option>
                                <el-option label="市级" value="市级"></el-option>
                                <el-option label="区级" value="区级"></el-option>
                                <el-option label="公司级" value="公司级"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="获取时间:" prop="GetDate">
                            <el-date-picker type="date" v-model="HonorForm.GetDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="颁发单位:" prop="IssuedUnits">
                            <el-input type="text" v-model="HonorForm.IssuedUnits"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否在公司:" prop="IsFactory">
                            <el-switch v-model="HonorForm.IsFactory"> </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="具体描述">
                            <el-input type="textarea" v-model="HonorForm.Explain"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <upload-files :files="HonorForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" :IsDisabled="false" :IsDel="false"></upload-files>
            </el-form>
        </el-dialog>
        <el-dialog title="调动详情" :visible.sync="TransferFormVisible" v-model="TransferFormVisible" :close-on-click-modal="false">
            <el-form ref="TransferForm" :model="TransferForm" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="UserName">
                            <el-input type="text" v-model="TransferForm.UserName"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="调入公司:" prop="IntoFactory">
                            <el-input type="text" v-model="TransferForm.IntoFactory">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="调入部门:" prop="IntoDpt">
                            <el-input type="text" v-model="TransferForm.IntoDpt"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="调入岗位:" prop="IntoPost">
                            <el-input type="text" v-model="TransferForm.IntoPost"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="调出公司:" prop="OutFactory">
                            <el-input type="text" v-model="TransferForm.OutFactory">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="调出部门:" prop="OutDpt">
                            <el-input type="text" :disabled="true" v-model="TransferForm.OutDpt">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="调出岗位:" prop="OutPost">
                            <el-input type="text" v-model="TransferForm.OutPost"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="调动原因:" prop="Reason">
                            <el-input type="text" v-model="TransferForm.Reason"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="调动日期:" prop="TransferDate">
                            <el-date-picker type="date" v-model="TransferForm.TransferDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用工性质:">
                            <el-select v-model="TransferForm.ContractNature" placeholder="请选择" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <upload-files :files="TransferForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" :IsDisabled="false" :IsDel="false"></upload-files>
            </el-form>
        </el-dialog>

        <el-dialog title="证书详情" :visible.sync="CertificateVisible" v-model="CertificateVisible" :close-on-click-modal="false">
            <el-form ref="CertificateForm" :model="CertificateForm" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="UserName">
                            <el-input type="text" :disabled="true" v-model="CertificateForm.UserName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证书名称:" prop="CertName">
                            <el-input type="text" v-model="CertificateForm.CertName">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="证书系列:" prop="CertType">
                            <el-select v-model="CertificateForm.CertType" placeholder="证书系列" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="类别:" prop="CertCategory">
                            <el-select v-model="CertificateForm.CertCategory" placeholder="类别" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专业:" prop="CertProfession">
                            <el-select v-model="CertificateForm.CertProfession" placeholder="证书系列" style="width: 100%">
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证书编号:" prop="CertCode">
                            <el-input type="text" v-model="CertificateForm.CertCode">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="注册编号:" prop="RegisterCode">
                            <el-input type="text" v-model="CertificateForm.RegisterCode">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="管理号:" prop="ManageCode">
                            <el-input type="text" v-model="CertificateForm.ManageCode">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发证单位:" prop="CertAssUnit">
                            <el-input type="text" v-model="CertificateForm.CertAssUnit">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="评定单位:" prop="CertFairUnit">
                            <el-input type="text" v-model="CertificateForm.CertFairUnit">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="签发时间:" prop="CertFairDate">
                            <el-date-picker type="date" v-model="CertificateForm.CertFairDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="变更日期:" prop="ChangeDate">
                            <el-date-picker type="date" v-model="CertificateForm.ChangeDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="有效期限:" prop="CertEndDate">
                            <el-date-picker type="date" v-model="CertificateForm.CertEndDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="存档日期:" prop="CertSaveDate">
                            <el-date-picker type="date" v-model="CertificateForm.CertSaveDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="补贴日期:" prop="SubsidyDate">
                            <el-date-picker type="date" v-model="CertificateForm.SubsidyDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="补贴金额:" prop="SubsidyMoney">
                            <el-input type="money" v-model="CertificateForm.SubsidyMoney">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="复审周期:" prop="CertCycle">
                            <el-select v-model="CertificateForm.CertCycle" placeholder="复审周期" style="width: 100%">
                                <el-option label="0" value="0"></el-option>
                                <el-option label="1" value="1"></el-option>
                                <el-option label="2" value="2"></el-option>
                                <el-option label="3" value="3"></el-option>
                                <el-option label="4" value="4"></el-option>
                                <el-option label="5" value="5"></el-option>
                                <el-option label="6" value="6"></el-option>
                                <el-option label="7" value="7"></el-option>
                                <el-option label="8" value="8"></el-option>
                                <el-option label="9" value="9"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发放周期:" prop="CerFFTime">
                            <el-select v-model="CertificateForm.CerFFTime" placeholder="发放周期" style="width: 100%">
                                <el-option label="无" value="无"></el-option>
                                <el-option label="半年度" value="半年度"></el-option>
                                <el-option label="季度" value="季度"></el-option>
                                <el-option label="月度" value="月度"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发放方式:" prop="CerFFType">
                            <el-select v-model="CertificateForm.CerFFType" placeholder="发放方式" style="width: 100%">
                                <el-option label="无" value="无"></el-option>
                                <el-option label="集团发放" value="集团发放"></el-option>
                                <el-option label="工资发放" value="工资发放"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="发放公司:" prop="PaymentAccount">
                            <el-select v-model="CertificateForm.PaymentAccount" placeholder="发放公司" style="width: 100%">
                                <el-option label="无" value="无"></el-option>
                                <el-option label="格瑞德集团" value="格瑞德集团"></el-option>
                                <el-option label="人工环境设计研究院" value="人工环境设计研究院"></el-option>
                                <el-option label="冷却设备有限公司" value="冷却设备有限公司"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="获取方式:" prop="CertGetType">
                            <el-select v-model="CertificateForm.CertGetType" placeholder="获取方式" style="width: 100%">
                                <el-option label="个人" value="个人"></el-option>
                                <el-option label="公司" value="公司"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证书状态:" prop="CertState">
                            <el-select v-model="CertificateForm.CertState" placeholder="证书状态" style="width: 100%">
                                <el-option label="在职未过期" value="在职未过期"></el-option>
                                <el-option label="在职已过期" value="在职已过期"></el-option>
                                <el-option label="离职未过期" value="离职未过期"></el-option>
                                <el-option label="离职已过期" value="离职已过期"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="存放地点:" prop="SavePlace">
                            <el-select v-model="CertificateForm.SavePlace" placeholder="存放地点">
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="使用有效期:" prop="ValidityDate">
                            <el-date-picker type="date" v-model="CertificateForm.ValidityDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="存放状态变更说明(借阅记录):" prop="CertSaveState" label-width="200px">
                            <el-select v-model="CertificateForm.CertSaveState" placeholder="选择">
                                <el-option label="已借用" value="已借用"></el-option>
                                <el-option label="未借用" value="未借用"></el-option>
                                <el-option label="综合办公室借用" value="综合办公室借用"></el-option>
                                <el-option label="离职带走" value="离职带走"></el-option>
                                <el-option label="离职未带走" value="离职未带走"></el-option>
                                <el-option label="换证复审" value="换证复审"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <label for="Summarize" class="el-form-item__label">备注:</label>
                        <el-input type="textarea" v-model="CertificateForm.Remark" style="width: 90%"></el-input>
                    </el-col>
                </el-row>
                <upload-files :files="CertificateForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" :IsDisabled="false" :IsDel="false"></upload-files>
                <el-row>
                    <el-col :span="24">
                        <label>承诺书:</label>
                    </el-col>
                </el-row>
                <upload-files :files="CertificateForm.CommitmentFileArry" :key="keyCommitment" action="/cyl/ftp/ftp/upload" :limit="20" :IsDisabled="false" :IsDel="false"></upload-files>
            </el-form>
        </el-dialog>
        <el-dialog title="旧系统记录" :visible.sync="historyVisible" v-model="historyVisible" :close-on-click-modal="false" width="60%">
            <el-table :data="historyBanks" highlight-current-row>
                <el-table-column prop="UserName" label="名称"> </el-table-column>
                <el-table-column label="日期" prop="NoticeDate" :formatter="formatStartTime"></el-table-column>
                <el-table-column label="备注" prop="Result"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="学习经历" :visible.sync="CustomeraddFormVisible" v-model="CustomeraddFormVisible" :close-on-click-modal="false">
            <el-form label-width="150px" ref="CustomeraddForm" :model="CustomeraddForm">
                <el-form-item label="开始时间:" prop="EnterTime">
                    <el-date-picker v-model="CustomeraddForm.EnterTime" value-format="yyyy-MM-dd" type="date" placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:" prop="GraduateTime">
                    <el-date-picker v-model="CustomeraddForm.GraduateTime" value-format="yyyy-MM-dd" type="date" placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="学校名称:" prop="GradauteSchool">
                    <el-input v-model="CustomeraddForm.GradauteSchool"></el-input>
                </el-form-item>
                <el-form-item label="专业:">
                    <el-input v-model="CustomeraddForm.Speciality"></el-input>
                </el-form-item>
                <el-form-item label="学历:">
                    <el-input v-model="CustomeraddForm.Certificate"></el-input>
                </el-form-item>
                <el-form-item label="证明人及电话">
                    <el-input v-model="CustomeraddForm.Voucher"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="CustomeraddFormVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="CustomeraddSubmit('CustomeraddForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="培训经历" :visible.sync="TrainingExperienceaddFormVisible" v-model="TrainingExperienceaddFormVisible" :close-on-click-modal="false">
            <el-form label-width="150px" ref="TrainaddForm" :model="TrainaddForm">
                <el-form-item label="开始时间:">
                    <el-date-picker v-model="TrainaddForm.StartTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:">
                    <el-date-picker v-model="TrainaddForm.EndTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="培训机构:">
                    <el-input v-model="TrainaddForm.Institutions"></el-input>
                </el-form-item>
                <el-form-item label="培训内容:">
                    <el-input type="textarea" v-model="TrainaddForm.Details"></el-input>
                </el-form-item>
                <el-form-item label="证书名称:">
                    <el-input v-model="TrainaddForm.InCertificate"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button v-on:click="TrainingExperienceaddFormVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="TrainaddSubmit('TrainaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="工作经历" :visible.sync="WorkExperienceaddFormVisible" v-model="WorkExperienceaddFormVisible" :close-on-click-modal="false">
            <el-form label-width="150px" ref="WorkaddForm" :model="WorkaddForm">
                <el-form-item label="开始时间:">
                    <el-date-picker v-model="WorkaddForm.StartTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:">
                    <el-date-picker v-model="WorkaddForm.EndTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="工作单位:">
                    <el-input v-model="WorkaddForm.WorkAddress"></el-input>
                </el-form-item>
                <el-form-item label="职位/岗位:">
                    <el-input v-model="WorkaddForm.LeavingReason"></el-input>
                </el-form-item>
                <el-form-item label="离职原因:">
                    <el-input type="textarea" v-model="WorkaddForm.BecaseWork"></el-input>
                </el-form-item>
                <el-form-item label="证明人及电话:">
                    <el-input v-model="WorkaddForm.WorkVoucher"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button v-on:click="WorkExperienceaddFormVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="WorkaddSubmit('WorkaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="家庭成员" :visible.sync="FamilyaddFormVisible" v-model="FamilyaddFormVisible" :close-on-click-modal="false">
            <el-form label-width="150px" ref="FamilyaddForm" :model="FamilyaddForm">
                <el-form-item label="姓名:" prop="FaName">
                    <el-input v-model="FamilyaddForm.FaName"></el-input>
                </el-form-item>
                <el-form-item label="与本人关系:" prop="Relationship">
                    <el-input v-model="FamilyaddForm.Relationship"></el-input>
                </el-form-item>
                <el-form-item label="职位/岗位:" prop="FaAge">
                    <el-input v-model="FamilyaddForm.WorkDpt"></el-input>
                </el-form-item>
                <el-form-item label="工作单位:" prop="WorkUnits">
                    <el-input v-model="FamilyaddForm.WorkUnits"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:" prop="PhoneNo">
                    <el-input v-model="FamilyaddForm.PhoneNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="FamilyaddFormVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="FamilyaddSubmit('FamilyaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <probationModals ref="probationModals"></probationModals>
        <patentModal ref="patentModal"></patentModal>
        <thesisModal ref="thesisModal"></thesisModal>
        <recordNotesModal ref="recordNotesModal"> </recordNotesModal>

        <postExplainModal ref="postExplainModal" @callback="getData"></postExplainModal>
</section>
</template>
<script>
    import util from "../../../util/date";
    import * as validate from "../../../util/validate";
    import UserChoose from "../../components/UserChoose";
    import UserChoosejob from "../../components/UserChoosejob";
    import UploadFiles from "../../components/UploadFiles";
    import {
        UserInfoGetInfoSharingCenter,
        GetCurrentUserInfo,
        GetAtPresentBaseInfo,
        ContractGetInfoList,
        CertificateGetInfoList,
        TransferGetInfoList,
        HonorGetInfoList,
        GetHonorDetail,
        DimissionGetInfoList,
        PositiveGetPositiveList,
        getGetPositiveDetail,
        SalaryBanksGetInfoList,
        ProfessionGetInfoList,
        ProfessionTopGetInfoList,
        GetAtPresentSalaryBanksInfo,
        UserInfosUpdateFileList,
        UserInfoGetFileArrayList,
        UserUpdateUserImage,
        SalaryBanksGetOldInfoListList,
        UserUpdateArchiveInfo,
        UpdateAuthorizedStrength,
        getProbationInfoList,
        getResearchResultInfoList,
        getPatentInfoList,
        getThesisInfoList,
        GetCertificateDetail,
        GetTrainSingInList
    } from "../../api/hr";
    import { getButtonList } from "../../promissionRouter";
    import Toolbar from "../../components/newToolbar";

    import probationModals from "../componentModals/probationModals"    // 试用
    import patentModal from "../componentModals/patentModal"    // 专利
    import thesisModal from "../componentModals/thesisModal"    // 论文
    import recordNotesModal from "../componentModals/recordNotesModal"    // 绝密档案
    import postExplainModal from "../componentModals/PostExplainModalNew"    // 岗位职责

    export default {
        components: { Toolbar, UserChoose, UserChoosejob, UploadFiles, probationModals, patentModal, thesisModal, recordNotesModal ,postExplainModal},
        
        data() {
            return {
                token: { "Authorization": "Bearer " + window.localStorage.getItem("access_token") },
                activeName: "基本信息",
                tabType: "基本信息", //当前所在的标签页
                loading: true,
                searchVal: "",
                currentPage: 1, // 当前页码
                total: null, // 总条数
                totaldata: null,
                pageSize: 20, // 每页的数据条数
                page: 1,
                currentPage1: 1, // 当前页码
                total1: null, // 总条数
                totaldata1: null,
                pageSize1: 20, // 每页的数据条数
                page1: 1,
                key: 0,
                namevalue:"",//
                keyCommitment:0,
                SakaryVisible: false,
                buttonListmsg: "",
                operation: false, // true:新增, false:编辑
                addUserVisible: false,
                IntoUserVisible: false,
                historyVisible: false,
                buttonList: [],
                historyBanks: [],
                SakaryForm: {
                    FileName: "",
                    FileAddress: "",
                },
                selectForm: {
                    userName: "",
                    // iDCard: "",
                    userState: "",
                    // isEmploy: "在职",
                    // dpt: "",
                    // factory: "",
                    post: "",
                },
                fileList: [{ FileName: "", URL: "" }],
                users: [],
                TrainingExperienceaddFormVisible: false,
                CustomeraddFormVisible: false,
                FamilyaddFormVisible: false,
                WorkExperienceaddFormVisible: false,
                FamilyaddFormVisible: false,
                userForm: {
                    UserName: null,
                    Dpt: null,
                    Hiredate: null,
                    Post: null,
                    MobilePhone: null,
                    ImageURL: null,
                    IsAuthorizedStrengthName: "",
                },
                UploadForm: {
                    FileArry: [],
                },
                dataForm: [],
                Contract: [],
                Certificate: [],
                SakaryForm: [],
                SakaryVisible: false,
                key: 0,
                Transfer: [],
                Honor: [],
                TransferForm: [],
                TransferFormVisible: false,
                currentPage: 1, // 当前页码
                total: null, // 总条数
                totaldata: null,
                pageSize: 20, // 每页的数据条数
                page: 1,
                HonorForm: [],
                HonorVisible: false,
                Dimission: [],
                Probation: [], // 试用
                researchResult: [], // 科技成果
                PatentList: [], // 专利
                ThesisList: [], // 论文
                TrainList: [],// 培训
                Positive: [],
                PermissionForm: [],
                permissionShowVisible: false,
                SalaryBanks: [],
                SalaryBanksForm: [],
                SalaryBanksVisible: false,
                Professions: [],
                ProfessionTop: [],
                CertificateForm: [],
                CertificateVisible: false,
                PresentSalary: [],
                CustomeraddForm: [],
                FamilyaddForm: [],
                TrainaddForm: [],
                WorkaddForm: [],
                Rewards: [],
                qyTypeOptions: [
                    {
                        value: "未婚",
                        label: "未婚",
                    },
                    {
                        value: "已婚",
                        label: "已婚",
                    },
                    {
                        value: "离异",
                        label: "离异",
                    },
                    {
                        value: "丧偶",
                        label: "丧偶",
                    },
                ],
                edTypeOptions: [
                    {
                        value: "无",
                        label: "无",
                    },
                    {
                        value: "初中以下",
                        label: "初中以下",
                    },
                    {
                        value: "初中",
                        label: "初中",
                    },
                    {
                        value: "中专",
                        label: "中专",
                    },
                    {
                        value: "高中",
                        label: "高中",
                    },
                    {
                        value: "大专",
                        label: "大专",
                    },
                    {
                        value: "本科",
                        label: "本科",
                    },
                    {
                        value: "研究生",
                        label: "研究生",
                    },
                    {
                        value: "博士",
                        label: "博士",
                    },
                    {
                        value: "博士后",
                        label: "博士后",
                    },
                ],
                tiTypeOptions: [
                    {
                        value: "无",
                        label: "无",
                    },
                    {
                        value: "助理工程师",
                        label: "助理工程师",
                    },
                    {
                        value: "中级工程师",
                        label: "中级工程师",
                    },
                    {
                        value: "高级工程师",
                        label: "高级工程师",
                    },
                    {
                        value: "初级会计师",
                        label: "初级会计师",
                    },
                    {
                        value: "中级会计师",
                        label: "中级会计师",
                    },
                    {
                        value: "高级会计师",
                        label: "高级会计师",
                    },
                    {
                        value: "初级经济师",
                        label: "初级经济师",
                    },
                    {
                        value: "中级经济师",
                        label: "中级经济师",
                    },
                    {
                        value: "高级经济师",
                        label: "高级经济师",
                    }
                ],
                jnTypeOptions: [
                    {
                        value: "无",
                        label: "无",
                    },
                    {
                        value: "初级技工",
                        label: "初级技工",
                    },
                    {
                        value: "中级技工",
                        label: "中级技工",
                    },
                    {
                        value: "高级技工",
                        label: "高级技工",
                    },
                    {
                        value: "技师",
                        label: "技师",
                    },
                    {
                        value: "高级技师",
                        label: "高级技师",
                    },],
                poTypeOptions: [
                    {
                        value: "群众",
                        label: "群众",
                    },
                    {
                        value: "团员",
                        label: "团员",
                    },
                    {
                        value: "党员",
                        label: "党员",
                    },
                    {
                        value: "无党派人士",
                        label: "无党派人士",
                    },
                    {
                        value: "其他",
                        label: "其他",
                    },
                ],
                adTypeOptions: [
                    {
                        value: "本市城镇",
                        label: "本市城镇",
                    },
                    {
                        value: "本市农村",
                        label: "本市农村",
                    },
                    {
                        value: "外省/市城镇",
                        label: "外省/市城镇",
                    },
                    {
                        value: "外省/市农村",
                        label: "外省/市农村",
                    },
                ],
                applyTypeOptions: [
                    {
                        value: "58同城",
                        label: "58同城",
                    },
                    {
                        value: "门口招聘栏",
                        label: "门口招聘栏",
                    },
                    {
                        value: "本公司员工介绍",
                        label: "本公司员工介绍",
                    },
                    {
                        value: "智联招聘",
                        label: "智联招聘",
                    },
                    {
                        value: "校园招聘会",
                        label: "校园招聘会",
                    },
                    {
                        value: "格瑞德官网",
                        label: "格瑞德官网",
                    },
                    {
                        value: "BOSS直聘",
                        label: "BOSS直聘",
                    },
                    {
                        value: "微信公众号",
                        label: "微信公众号",
                    },
                    {
                        value: "其他",
                        label: "其他",
                    },
                ],
            };
        },
        methods: {
            tableRowClassName({ row, rowIndex }) {
                console.log(row);
                if (row.IsValid === true) {
                    return 'success-row';
                } 
                return '';
            },
            IsAuthorizedStrengthChange(name) {
                this.$confirm("确定要修改用户状态吗？", "提示", {}).then(() => {
                    let isAuthorizedStrength = null;
                    if (name == "在编") {
                        isAuthorizedStrength = true;
                    } else {
                        isAuthorizedStrength = false;
                    }
                    UpdateAuthorizedStrength({
                        userId: this.userForm["UserId"],
                        isAuthorizedStrength: isAuthorizedStrength,
                    }).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: "修改成功",
                                type: "success",
                                duration: 5000,
                            });
                            GetCurrentUserInfo({ userId: this.userForm["UserId"] }).then(
                                (res) => {
                                    this.userForm = res.data.response;
                                    this.userForm["Hiredate"] = util.formatDate.format(
                                        new Date(this.userForm["Hiredate"]),
                                        "yyyy-MM-dd"
                                    );
                                    if (this.userForm["IsAuthorizedStrength"]) {
                                        this.userForm.IsAuthorizedStrengthName = "在编";
                                    } else {
                                        this.userForm.IsAuthorizedStrengthName = "不在编";
                                    }
                                }
                            );
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                });
            },
            submitForm(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    let para = Object.assign({}, this.dataForm);
                    console.log(this.dataForm);
                    if (para.SpecialPersonnel != null && para.SpecialPersonnel.length > 0) {
                        para.SpecialPersonnel = para.SpecialPersonnel.join(",");
                    } else {
                        para.SpecialPersonnel = "";
                    }
                    UserUpdateArchiveInfo(para).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: "修改成功",
                                type: "success",
                                duration: 5000,
                            });
                            // this.userDetailVisible = false;
                            // this.getUsers();
                        }
                    });
                });
            },
            TrainingExperienceDetail(row, index) {
                this.TrainaddForm = Object.assign({}, row);
                this.TrainaddForm.rowIndex = index;
                this.TrainingExperienceaddFormVisible = true;
            },
            TrainaddSubmit(formName) {
                let _this = this;

                let para = Object.assign({}, this.TrainaddForm);
                console.log(this.TrainaddForm);
                if (this.TrainaddForm.rowIndex != undefined) {
                    this.$set(this.dataForm.Traineds, this.TrainaddForm.rowIndex, para);
                } else {
                    this.dataForm.Traineds.push(para);
                }

                this.TrainingExperienceaddFormVisible = false;
            },
            workAdd() {
                this.WorkExperienceaddFormVisible = true;
                this.WorkaddForm = {
                    StartTime: null,
                    EndTime: null,
                    WorkAddress: null,
                    LeavingReason: null,
                    BecaseWork: null,
                    WorkVoucher: null,
                };
            },
            WorkaddSubmit(formName) {
                let _this = this;
                this.$refs.WorkaddForm.validate((vaild) => {
                    if (vaild) {
                        let para = Object.assign({}, this.WorkaddForm);
                        if (this.WorkaddForm.rowIndex != undefined) {
                            this.$set(this.dataForm.Workeds, this.WorkaddForm.rowIndex, para);
                        } else {
                            this.dataForm.Workeds.push(para);
                        }

                        this.WorkExperienceaddFormVisible = false;
                    } else {
                        this.$message({
                            message: "请填写完整数据!",
                            type: "error",
                        });
                    }
                });
            },
            PostEstablishment(){    //岗位说明书
            let row = this.currentRow;
            if (!row) {
            this.$message({
                message: "请选择要编辑的一行数据！",
                type: "error",
            });
            return;
            }
            let asdasd = "";
            console.log(row);
                if (row.PostId != null) {
                    this.$refs.postExplainModal.operation = true; // 添加
                    this.$refs.postExplainModal.optType = false;   // 编辑
                    this.$refs.postExplainModal.optType1 = true;   // 编辑
                    this.$refs.postExplainModal.onShowDlg(row.PostId,row.Factory,row.PostName,row.DptName);
                }else{
                    this.$refs.postExplainModal.operation = true; // 添加
                    this.$refs.postExplainModal.optType = false;   // 编辑
                    this.$refs.postExplainModal.optType1 = true;   // 编辑
                    this.$refs.postExplainModal.onShowDlg();
                }
            },
            WorkExperienceDetail(row, index) {
                this.WorkaddForm = Object.assign({}, row);
                this.WorkaddForm.rowIndex = index;
                this.WorkExperienceaddFormVisible = true;
            },
            familyAdd() {
                this.FamilyaddFormVisible = true;
                this.FamilyaddForm = {
                    FaName: null,
                    Relationship: null,
                    WorkDpt: null,
                    WorkUnits: null,
                    PhoneNo: null,
                };
            },
            FamilyaddSubmit(formName) {
                let _this = this;
                this.$refs.FamilyaddForm.validate((vaild) => {
                    if (vaild) {
                        let para = Object.assign({}, this.FamilyaddForm);
                        if (this.FamilyaddForm.rowIndex != undefined) {
                            this.$set(this.dataForm.Familys, this.FamilyaddForm.rowIndex, para);
                        } else {
                            this.dataForm.Familys.push(para);
                        }

                        this.FamilyaddFormVisible = false;
                    } else {
                        this.$message({
                            message: "请填写完整数据!",
                            type: "error",
                        });
                    }
                });
            },
            FamilyDetail(row, index) {
                this.FamilyaddForm = Object.assign({}, row);
                this.FamilyaddForm.rowIndex = index;
                this.FamilyaddFormVisible = true;
            },
            CustomerhandleDetail(row, index) {
                this.CustomeraddForm = Object.assign({}, row);
                this.CustomeraddForm.rowIndex = index;
                this.CustomeraddFormVisible = true;
            },
            //学习经历添加提交
            CustomeraddSubmit(formName) {
                let _this = this;
                this.$refs.CustomeraddForm.validate((vaild) => {
                    if (vaild) {
                        let para = Object.assign({}, this.CustomeraddForm);
                        if (this.CustomeraddForm.rowIndex != undefined) {
                            this.$set(
                                this.dataForm.Educateds,
                                this.CustomeraddForm.rowIndex,
                                para
                            );
                        } else {
                            this.dataForm.Educateds.push(para);
                        }

                        this.CustomeraddFormVisible = false;
                    } else {
                        this.$message({
                            message: "请填写完整数据!",
                            type: "error",
                        });
                    }
                });
            },
            familyAdd() {
                this.FamilyaddFormVisible = true;
                this.FamilyaddForm = {
                    FaName: null,
                    Relationship: null,
                    WorkDpt: null,
                    WorkUnits: null,
                    PhoneNo: null,
                };
            },
            workAdd() {
                this.WorkExperienceaddFormVisible = true;
                this.WorkaddForm = {
                    StartTime: null,
                    EndTime: null,
                    WorkAddress: null,
                    LeavingReason: null,
                    BecaseWork: null,
                    WorkVoucher: null,
                };
            },
            //学习经历添加
            customerhandleAdd() {
                this.CustomeraddFormVisible = true;
                this.CustomeraddForm = {
                    EnterTime: null,
                    GraduateTime: null,
                    GradauteSchool: null,
                    Speciality: null,
                    Certificate: null,
                    Voucher: null,
                };
            },
            trainAdd() {
                this.TrainingExperienceaddFormVisible = true;

                this.TrainaddForm = {
                    StartTime: null,
                    EndTime: null,
                    Institutions: null,
                    Details: null,
                    InCertificate: null,
                };
            },
            phonecheck() {
                if (!validate.isMobile(this.dataForm.MobilePhone)) {
                    this.$message({
                        message: "该输入无效,请输入其他有效手机号",
                        type: "error",
                        duration: 5000,
                    });
                    this.dataForm.MobilePhone = null;
                    return;
                }
            },
            nocheck() {
                if (!validate.validateIdNo(this.dataForm.idCard)) {
                    this.$message({
                        message: "该输入无效,请输入有效身份证号",
                        type: "error",
                        duration: 5000,
                    });
                    this.dataForm.idCard = null;
                    return;
                }
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
            formatStartTime: function (row, colume) {
                var date = row[colume.property];
                if (date == undefined) {
                    return "";
                }
                return util.formatDate.format(new Date(date), "yyyy-MM-dd");
            },
            handledbClick(row, event, column) {
                console.log(row, event, column);
            },

            callFunction(item) {
                this[item.Func].apply(this, item);
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.page = val;
                this.getData(false);
            },
            //获取列表
            getData(isCurrent = true) {
                if (isCurrent) {
                    this.page = 1;
                }

                let para = {
                    page: this.page,
                    size: this.pageSize,
                    // iDCard: this.selectForm.iDCard,
                    username: this.selectForm.userName,
                    postname: this.selectForm.post,
                };
                UserInfoGetInfoSharingCenter(para).then((res) => {
                    console.log(res);
                    this.total = res.data.response.pageCount;
                    this.totaldata = res.data.response.dataCount;
                    this.users = res.data.response.data;
                    this.loading = false;
                    console.log(res.data.response.data)
                });
            },
            getButtonList2(routers) {
                let _this = this;
                routers.forEach((element) => {
                    let path = this.$route.path.toLowerCase();
                    if (element.path && element.path.toLowerCase() == path) {
                        _this.buttonList = element.children;
                        return;
                    } else if (element.children) {
                        _this.getButtonList(element.children);
                    }
                });
            },
            async handleEdit() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                this.activeName = "基本信息";
                this.tabType = "基本信息";
                GetCurrentUserInfo({ userId: row.UserId }).then((res) => {
                    this.userForm = res.data.response;
                    this.userForm["Hiredate"] = util.formatDate.format(
                        new Date(this.userForm["Hiredate"]),
                        "yyyy-MM-dd"
                    );
                    if (this.userForm["IsAuthorizedStrength"]) {
                        this.userForm.IsAuthorizedStrengthName = "在编";
                    } else {
                        this.userForm.IsAuthorizedStrengthName = "不在编";
                    }
                    this.getUsers();
                    this.getFilesArray();
                    this.key += 1;
                    this.SakaryVisible = true;
                    this.operation = false;
                });
            },
            async handecordNotes() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                this.$refs.recordNotesModal.onShowDlg(row.UserId);
            },
            async Print() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                this.$router.push({ path: "/printUser", query: { userId: row.UserId,Type :"Archuves" } });
            },
            async handleUpdate() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                GetCurrentUserInfo({ userId: row.UserId }).then((res) => {
                    this.userForm = res.data.response;
                    this.userForm["Hiredate"] = util.formatDate.format(
                        new Date(this.userForm["Hiredate"]),
                        "yyyy-MM-dd"
                    );
                    this.getUsers();
                    this.getFilesArray();
                    this.key += 1;
                    this.SakaryVisible = true;
                    this.operation = true;
                });
            },

            handleClick(tab, event) {
                console.log(tab.name);
                if (this.tabType == tab.name)
                    return;
                this.tabType = tab.name;
                
                switch (tab.name) {
                    case "基本信息":
                        this.getUsers();
                        break;
                    case "档案信息":
                        //  this.getData();
                        break;
                    case "合同信息":
                        this.getContract();
                        break;
                    case "证书信息":
                        this.getCertificate();
                        break;
                    case "调动信息":
                        this.getTransfer();
                        break;
                    case "荣誉信息":
                        this.getHonor();
                        break;
                    case "离职记录":
                        this.getDimission();
                        break;
                    case "试用信息":
                        this.getProbation();
                        break;

                    case "转正信息":
                        this.getPositive();
                        break;
                    case "薪资调整记录":
                        this.getSalaryBanks();
                        break;
                    case "职称信息":
                        this.getProfession();
                        break;
                    case "首席工程师":
                        this.getProfessionTop();
                        break;
                    case "薪资信息":
                        this.getAtPresentSalaryBanksInfo();
                        break;
                    case "奖惩信息":
                        this.getRewards();
                        break;
                    case "专利信息":
                        this.getPatent();
                        break;
                    case "论文信息":
                        this.getThesis();
                        break;
                    case "科技成果":
                        this.getResearchResult();
                        break;
                    case "培训信息":
                        this.getTrain();
                        break;
                        
                    //       case "员工附件":
                    //       this.getFilesArray()
                    //   break
                }
            },
            ondblclick(row, column, cell, event) {
                //let row = this.currentRow;
                switch (this.tabType) {
                    case "试用信息":
                        this.$refs.probationModals.onShowDlg(row.Id);
                        break;
                    case '专利信息':
                        {
                            this.$refs.patentModal.onShowDlg(row.Id);
                        } break;
                    case '论文信息':
                        {
                            this.$refs.thesisModal.onShowDlg(row.Id);
                        } break;
                    case '科技成果':
                        {
                            if (row.Classify === "论文") {
                                this.$refs.thesisModal.onShowDlg(row.Id);
                            }
                            else {
                                this.$refs.patentModal.onShowDlg(row.Id);
                            }
                        } break;
                }
            },
            getRewards() {
                this.$api.UserInfoGetRewardsList({ userId: this.userForm.UserId }).then((res) => {
                    this.Rewards = res.data.response;
                });
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.page1 = val;
                this.getHonor();
            },
            CertificateDetail(row) {
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                GetCertificateDetail({ id: row.Id }).then((res) => {
                    this.CertificateForm = res.data.response;
                    this.key += 1;
                    this.keyCommitment += 1;
                    this.CertificateVisible = true;
                });
                //this.CertificateForm = Object.assign({}, row);
                //this.key += 1;
                //this.CertificateVisible = true;
            },
            getUsers() {
                console.log(this.userForm);
                GetAtPresentBaseInfo({ userId: this.userForm["UserId"] }).then((res) => {
                    console.log(res);
                    if (res.data.response != null) {
                        this.dataForm = res.data.response;

                        if (this.dataForm.SpecialPersonnel) {
                            this.dataForm.SpecialPersonnel = this.dataForm.SpecialPersonnel.split(
                                ","
                            );
                        }
                        this.dataForm["ApplyDate"] = util.formatDate.format(
                            new Date(this.dataForm["ApplyDate"]),
                            "yyyy-MM-dd"
                        );
                        this.dataForm["Birthday"] = util.formatDate.format(
                            new Date(this.dataForm["Birthday"]),
                            "yyyy-MM-dd"
                        );
                    }
                });
            },
            getFilesArray() {
                this.UploadForm.FileArry = [];
                UserInfoGetFileArrayList({ PId: this.userForm["UserId"] }).then((res) => {
                    this.key += 1;
                    this.UploadForm.FileArry = res.data.response;
                });
            },
            getContract() {
                ContractGetInfoList({ userId: this.userForm["UserId"] }).then((res) => {
                    this.Contract = res.data.response;
                });
            },
            getCertificate() {
                CertificateGetInfoList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.Certificate = res.data.response;
                    }
                );
            },
            getTransfer() {
                TransferGetInfoList({ userId: this.userForm["UserId"] }).then((res) => {
                    this.Transfer = res.data.response;
                });
            },
            getHonor() {
                HonorGetInfoList({
                    userId: this.userForm["UserId"],
                    page: this.page1,
                    size: this.pageSize1,
                }).then((res) => {
                    this.Honor = res.data.response.data;
                    this.total1 = res.data.response.pageCount;
                    this.totaldata1 = res.data.response.dataCount;
                });
            },
            TransferDetail(row) {
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                this.TransferForm = Object.assign({}, row);
                this.key += 1;
                this.TransferFormVisible = true;
            },
            HonorDetail(row) {
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                GetHonorDetail({ id: row.Id }).then((res) => {
                    this.HonorForm = res.data.response;
                    this.key += 1;
                    this.HonorVisible = true;
                });
            },

            getDimission() {
                DimissionGetInfoList({ userId: this.userForm["UserId"] }).then((res) => {
                    console.log(res);
                    console.log(res.data.response);
                    this.Dimission = res.data.response;
                });
            },
            getProbation() {
                getProbationInfoList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.Probation = res.data.response;
                    }
                );
            },
            getPatent() {
                getPatentInfoList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.PatentList = res.data.response.data;
                    }
                );
            },
            getThesis() {
                getThesisInfoList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.ThesisList = res.data.response.data;
                    }
                );
            },
            getResearchResult() {
                getResearchResultInfoList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        console.log(res)
                        this.researchResult = res.data.response.data;
                    }
                );
            },
            getTrain() {
                GetTrainSingInList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.TrainList = res.data.response;
                    }
                );
            },
            getPositive() {
                PositiveGetPositiveList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.Positive = res.data.response;
                    }
                );
            },
            PositiveDetail(row) {
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                getGetPositiveDetail({ id: row.Id }).then((res) => {
                    this.PermissionForm = res.data.response;
                    this.key += 1;
                    this.permissionShowVisible = true;
                });
            },
            getSalaryBanks() {
                SalaryBanksGetInfoList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.SalaryBanks = res.data.response;
                    }
                );
            },
            SalaryBanksDetail(row) {
                if (!row) {
                    this.$message({
                        message: "请选择要编辑的一行数据！",
                        type: "error",
                    });
                    return;
                }
                this.SalaryBanksForm = Object.assign({}, row);
                this.key += 1;
                this.SalaryBanksVisible = true;
            },
            getProfession() {
                console.log(1111);
                ProfessionGetInfoList({ userId: this.userForm["UserId"] }).then((res) => {
                    this.Professions = res.data.response;
                });
            },
            getProfessionTop() {
                ProfessionTopGetInfoList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.ProfessionTop = res.data.response;
                    }
                );
            },
            getAtPresentSalaryBanksInfo() {
                GetAtPresentSalaryBanksInfo({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        console.log(res);
                        if (res.data.response == null)
                            this.PresentSalary = {};
                        else
                            this.PresentSalary = res.data.response;
                    }
                );
            },
            UploadFormSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.UploadForm.PId = this.userForm["UserId"];
                    let para = Object.assign({}, this.UploadForm);
                    console.log(para);
                    UserInfosUpdateFileList(para).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: "添加成功",
                                type: "success",
                                duration: 5000,
                            });
                            this.SakaryVisible = false;
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                    this.getData();
                });
            },
            dealFiles(data) {
                this.UploadForm.FileArry = data.backData;
                // this.fileList = data.backData;
                // console.log('dealFiles-list:' + this.fileList);
            },
            OSOnSuccess(response, file, fileList) {
                this.userForm.ImageURL = response.data.httpHost + response.data.filePath;
                this.UserUpdateUserImage();
            },

            UserUpdateUserImage() {
                UserUpdateUserImage(this.userForm).then((res) => {
                    console.log(res);
                    if (res.data.success) {
                        this.$message({
                            message: "更新头像成功",
                            type: "success",
                            duration: 5000,
                        });
                        this.getData();
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "error",
                            duration: 5000,
                        });
                    }
                });
            },
            resetDateFilter() {
                SalaryBanksGetOldInfoListList({ userId: this.userForm["UserId"] }).then(
                    (res) => {
                        this.historyBanks = res.data.response;
                    }
                );
                this.historyVisible = true;
            },
            // 回车事件
            keyupEnter() {
                if (window.event.keyCode == 13) {
                    this.getData();// 查询方法
                }
            },
        },
        mounted() {
            this.getData();
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            let buttons = window.localStorage.buttList
                ? JSON.parse(window.localStorage.buttList)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers, buttons);
            
            window.addEventListener("keydown", this.keyupEnter);
        },
        destroyed() {
            window.removeEventListener("keydown", this.keyupEnter);
        },
        //如果页面有keep-alive缓存功能，这个函数会触发
        activated() {
            window.addEventListener("keydown", this.keyupEnter);
        },
        //如果页面有keep-alive缓存功能，这个函数会触发
        deactivated() {
            window.removeEventListener("keydown", this.keyupEnter);
        }
    };
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.personal-relation {
  overflow: hidden;
}
</style>

