<template>
    <!--加密档案列表-->
    <div>
        <el-dialog title="查看" :visible.sync="dlgShowVisible" v-model="dlgShowVisible" :close-on-click-modal="false">
            <el-button @click="AddRecord" type="primary">添加记录</el-button>
            <el-table :data="dlgShowForm"
                      style="width: 100%">
                <el-table-column prop="CreateDate"
                                 label="创建日期"
                                 width="180">
                </el-table-column>
                <el-table-column prop="CreateUserName"
                                 label="创建人"
                                 width="180">
                </el-table-column>
                <el-table-column prop="Content"
                                 label="内容">
                </el-table-column>
                <el-table-column fixed="right"
                                 label="操作"
                                 width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        <el-button @click.native.prevent="deleteRow(scope.$index,scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <recordNotesDetailModal ref="recordNotesDetailModal" @dlgCallBack="dlgCallBack"> </recordNotesDetailModal>
    </div>
</template>

<script>
    import {
        GetRecordNotesInfoList,
        DeleteRecordNotesInfo,
    } from "../../api/hr";
    import recordNotesDetailModal from "@/views/componentModals/recordNotesDetailModal";
    export default {
        components: { recordNotesDetailModal },
        methods: {
            // 获取数据显示
            onShowDlg(pid) {
                this.userId = pid;
                console.log(pid);
                GetRecordNotesInfoList({ userId: pid }).then((res) => {
                    console.log(res);
                    this.dlgShowForm = res.data.response;
                    this.key += 1;
                   // this.dlgShowForm = [{ date: "2021-12-06", name: "hehe", address: "内容" }];
                    this.dlgShowVisible = true;
                });
            },
            handleClick(rows) {
                console.log("row", rows)
                this.$refs.recordNotesDetailModal.onShowDlg(rows.Id, this.userId);
            },
            deleteRow(index, rows) {
                this.$confirm("确定要删除吗？", "提示", {}).then(() => {
                    console.log("this.$refs", this.$refs)
                    DeleteRecordNotesInfo({ id: rows.Id }).then((res) => {
                        this.dlgShowForm.splice(index, 1);
                    });
                });
                
                
            },
            AddRecord() {
                console.log("AddRecord", this.$refs);
                this.$refs.recordNotesDetailModal.onShowDlg("", this.userId);
            },
            dlgCallBack() {
                GetRecordNotesInfoList({ userId: this.userId }).then((res) => {
                    console.log(res);
                    this.dlgShowForm = res.data.response;
                    this.key += 1;
                    // this.dlgShowForm = [{ date: "2021-12-06", name: "hehe", address: "内容" }];
                    this.dlgShowVisible = true;
                });
            }
        },
        data() {
            return {
                userId:"",
                dlgShowVisible: false,
                dlgShowForm: [],
                
            }
        }
    }
</script>

<style scoped>
</style>