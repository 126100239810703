<template>
    <!--加密档案详情-->
    <div>
        <el-dialog title="查看" :visible.sync="dlgShowVisible" v-model="dlgShowVisible" :close-on-click-modal="false">
            <el-form label-width="100px" ref="dlgShowForm" :model="dlgShowForm" class="demo-form-inline">

                <el-row>
                    <el-col :span="24" v-show ="IsEdit">
                        <el-form-item prop="CreateDate" label="创建日期:">
                            <el-date-picker type="date" v-model="dlgShowForm.CreateDate" value-format="yyyy-MM-dd" :disabled="!optType"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-show ="IsEdit">
                        <el-form-item label="创建人:" prop="CreateUserName">
                            <el-input type="text" :disabled="!optType" v-model="dlgShowForm.CreateUserName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="内容:" prop="Content">
                            <el-input type="textarea" v-model="dlgShowForm.Content" :disabled="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <upload-files :files="dlgShowForm.Files" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" v-bind="upLoadPost"></upload-files>
                <el-row v-if="optType">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('dlgShowForm')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {
        GetRecordNotesInfo,
        AddRecordNotesInfo,
        UpdateRecordNotesInfo,
    } from "../../api/hr";
    import UploadFiles from "../../components/UploadFiles";
    export default {
        components: { UploadFiles },
        methods: {
            // 获取数据显示
            onShowDlg(pid, userId) {
                
                this.userId = userId;

                if (pid == "" || pid == null) {
                    let date = new Date()
                    let year = date.getFullYear()
                    let month = date.getMonth() + 1
                    let day = date.getDate()
                    let time = `${year}-${month}-${day}`;
                    this.optType = true;
                    this.upLoadPost = { IsDisabled: true, IsDel: true };
                    this.dlgShowForm = {
                        CreateDate: new Date(), createUserName: "", content: "", UserId: userId, Files: []
                    };
                    this.key += 1;
                    this.IsEdit = false;
                    this.dlgShowVisible = true;
                    console.log("onShowDl111g", this.userId)
                }
                else {
                    console.log(pid);
                    GetRecordNotesInfo({ id: pid }).then((res) => {
                        console.log(res);
                        this.dlgShowForm = res.data.response;
                        this.optType = false;
                        this.key += 1;
                        //this.dlgShowForm = { date: "2021-12-06", name: "hehe", address: "内容" };
                        this.upLoadPost = {
                            IsDisabled: false,
                            IsDel: false
                        };
                        this.IsEdit = true;
                        this.dlgShowVisible = true;
                    });
                }
                
            },
            dealFiles(data) {
                console.log(data.backData);
                this.dlgShowForm.Files = data.backData;
                // this.fileList = data.backData;
                // console.log('dealFiles-list:' + this.fileList);
            },
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.dlgShowForm);
                            if (this.optType) {
                                //新增
                                AddRecordNotesInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$emit("dlgCallBack");
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgShowVisible = false;
                                        
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            } else {
                                //修改
                                UpdateRecordNotesInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "修改成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgShowVisible = false;
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            }
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },
        },
        data() {
            return {
                IsEdit: false,
                userId:"",
                key: 0,
                optType:false,
                dlgShowVisible: false,
                dlgShowForm: {},
                upLoadPost: { // upload-files 传值
                    IsDisabled: false,
                    IsDel: false
                },
            }
        }
    }
</script>

<style scoped>
</style>